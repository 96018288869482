import Head from 'next/head'
import dynamic from 'next/dynamic'

import { loadStripe } from '@stripe/stripe-js'
import { CSC_PAYMENTS_PK } from '@/services/Configuration'

const Theme = dynamic(() => import('@csc/dls/Theme'))
const Navbar = dynamic(() => import('@/components/Sitewide/Navbar'))
const Footer = dynamic(() => import('@/components/Sitewide/Footer'))
const BackToTop = dynamic(() => import('@/components/Sitewide/BackToTop'))
const Meta = dynamic(() => import('@/components/Sitewide/Meta'))
const SvgNs = dynamic(() => import('@/components/Sitewide/SvgNs'))
const Banner = dynamic(() => import('@/components/Sitewide/Banner'))
const Elements = dynamic(() => import('@stripe/react-stripe-js').then((mod) => mod.Elements))
const LinkCanonical = dynamic(import('@/components/LinkCanonical/LinkCanonical'))

const stripePromise = loadStripe(CSC_PAYMENTS_PK)

const DefaultLayout = ({
  children,
  meta,
  pageType = '',
  sitewide,
  productAdded = false,
}) => (
  <Elements stripe={stripePromise}>
    <Theme
      themeId="zg"
      ScriptContainer={Head}>
      <style jsx>
        {`
        @font-face {
          font-family: 'NeutrafaceText-Book';
          src: url('/__statics/fonts/NeutrafaceText-Book.woff2');
        }

        @font-face {
          font-family: 'NeutrafaceText-Demi';
          src: url('/__statics/fonts/NeutrafaceText-Demi.woff2');
        }

        @font-face {
          font-family: 'AveresTitle-Regular';
          src: url('/__statics/fonts/AveresTitle-Regular.woff');
        }
      `}
      </style>
      <LinkCanonical path={meta.path}/>
      <Meta {...meta} />
      {pageType !== 'cart' &&
        sitewide.banner &&
        <Banner {...sitewide.banner} />
      }
      <SvgNs />
      {sitewide.meganav &&
        <Navbar
          meganav={sitewide.meganav}
          pageType={pageType}
          productAdded={productAdded}
        />
      }
      <div data-content-region="header_bottom" />
      {children}
      <Footer />
      <BackToTop />
    </Theme>
  </Elements>
)

export default DefaultLayout
